<template>
  <div class="container">
    <el-tabs v-model="activeName"
             type="card">
      <el-tab-pane label="实时数据消耗"
                   name="first">
        <el-alert title="仅支持查询2019-12-28后开始的代金券/支付立减活动消耗数据，查询此时间之前的批次号将出现数据不准确/查无批次号的情况。"
                  type="warning"
                  show-icon>
        </el-alert>
        <el-alert title="实时数据持续有变化，最终数据以活动结束后下载的明细为准。"
                  type="warning"
                  class="mt-15"
                  show-icon>
        </el-alert>
        <el-row class="mt-15">
          <el-input v-model="stock_id"
                    class="width300"
                    placeholder="请输入批次号">
            <el-button slot="append"
                       @click="onSearch"
                       icon="el-icon-search"></el-button>
          </el-input>
        </el-row>
        <el-card class="box-card mt-15"
                 style="width:400px"
                 v-if="detail">
          <div slot="header"
               class="clearfix">
            <span>{{detail.stock_name}}</span>
          </div>
          <div class="text item">
            <div class="left">活动类型</div>
            <div class="right">
              {{detail.stock_type==='DISCOUNT_CUT'? '支付立减':''}}
              {{detail.stock_type==='NORMAL' ? '代金券':''}}
              {{detail.stock_type==='OTHER' ? '其他':''}}
            </div>
          </div>
          <div class="text item">
            <div class="left">批次状态</div>
            <div class="right">
              {{detail.status==='unactivated'? '未激活':''}}
              {{detail.status==='audit' ? '审核中':''}}
              {{detail.status==='running' ? '运行中':''}}
              {{detail.status==='stoped' ? '已停止':''}}
            </div>
          </div>
          <div class="text item"
               v-if="detail.stock_type==='NORMAL'">
            <div class="left">已发券数</div>
            <div class="right">{{detail.distributed_coupons}}</div>
          </div>
          <div class="text item"
               v-if="detail.stock_type==='NORMAL'">
            <div class="left">消耗次数</div>
            <div class="right">{{detail.count}}</div>
          </div>
          <div class="text item"
               v-if="detail.stock_type==='NORMAL'">
            <div class="left">消耗金额</div>
            <div class="right">{{detail.total_money/100}}</div>
          </div>
          <div class="text item"
               v-if="detail.stock_type==='DISCOUNT_CUT'">
            <div class="left">减价笔数</div>
            <div class="right">{{detail.count}}</div>
          </div>
          <div class="text item"
               v-if="detail.stock_type==='DISCOUNT_CUT'">
            <div class="left">已消耗总金额</div>
            <div class="right">{{detail.total_money/100}}</div>
          </div>
          <div class="text item"
               v-if="detail.stock_type==='DISCOUNT_CUT'">
            <div class="left">今日已消耗金额</div>
            <div class="right">{{detail.today_money/100}}</div>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="消耗明细下载"
                   name="second">
        <el-alert title="仅支持下载2018-11-01后结束且有产生消耗的批次明细，搜索后请在30秒内下载明细。"
                  type="warning"
                  show-icon>
        </el-alert>
        <el-alert title="活动结束次日10:00之后才可下载到明细。"
                  type="warning"
                  class="mt-15"
                  show-icon>
        </el-alert>
        <el-row class="mt-15">
          <el-input v-model="stock_id2"
                    class="width300"
                    placeholder="请输入批次号">
            <el-button slot="append"
                       @click="search"
                       icon="el-icon-search"></el-button>
          </el-input>
        </el-row>
        <el-card class="box-card mt-15"
                 style="width:400px"
                 v-if="detail2">
          <div slot="header"
               class="clearfix">
            <span>{{detail2.stock_name}}</span>
          </div>
          <div class="text item">
            <div class="left">批次号</div>
            <div class="right">
              {{detail2.stock_id}}
            </div>
          </div>
          <div class="text item">
            <div class="left">结束时间</div>
            <div class="right">
              {{detail2.available_end_time}}
            </div>
          </div>
          <div class="text item">
            <div class="left">活动类型</div>
            <div class="right">
              {{detail2.stock_type==='DISCOUNT_CUT'?'支付立减':''}}
              {{detail2.stock_type==='NORMAL'?'代金券':''}}
              {{detail2.stock_type==='OTHER'?'其他':''}}
            </div>
          </div>
          <div class="text item">
            <div class="left">下载</div>
            <div class="right">
              <el-button type="text"
                         @click="downLoad">下载明细</el-button>
            </div>
          </div>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { stockQuery, fetchStocks } from '@/api/market'
export default {
  name: 'Query',
  data () {
    return {
      searchParams: {},
      activeName: 'first',
      stock_id: '',
      stock_id2: '',
      detail: null,
      detail2: null
    }
  },
  components: {
  },
  methods: {
    async onSearch () {
      const res = await stockQuery({
        stock_id: this.stock_id
      })
      if (res.meta.code === 0) {
        this.detail = res.data.res
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    downLoad (url) {
      window.open(this.down_url, '_blank')
    },
    async search (e) {
      const res = await fetchStocks({
        stock_id: this.stock_id2
      })
      if (res.meta.code === 0) {
        this.detail2 = res.data.details
        this.down_url = res.data.down_url
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  .left,
  .right {
    flex: 1;
    font-size: 16px;
  }
  .right {
    color: #666666;
    font-size: 14px;
    text-align: right;
  }
}
</style>
